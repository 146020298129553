import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "styles/colors";

const LinkStyles = css`
  color: ${colors.ACCENT_BLUE_500};
  &:hover {
    color: ${colors.ACCENT_BLUE_600};
    text-decoration: none;
  }
`;

export const PlainLink = styled.a`
  ${LinkStyles}

  height: 100%;
`;

export default styled(Link)`
  ${LinkStyles}
`;
