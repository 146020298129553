class AuthProvider {
  private static instance: AuthProvider;
  private tokenGenerator?: () => Promise<string>;

  /* eslint-disable @typescript-eslint/no-empty-function */
  private constructor() {}

  setTokenGenerator(tokenGenerator: () => Promise<string>) {
    this.tokenGenerator = tokenGenerator;
  }

  removeTokenGenerator() {
    this.tokenGenerator = undefined;
  }

  async generateToken() {
    if (!this.tokenGenerator) {
      throw new Error("tokenGenerator accessed without initialization");
    }
    return await this.tokenGenerator();
  }

  ready(): boolean {
    return !!this.tokenGenerator;
  }

  public static get Instance() {
    return this.instance || (this.instance = new this());
  }
}

export default AuthProvider.Instance;
